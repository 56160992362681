<script setup lang="ts">
import { Sheet } from 'bottom-sheet-vue3'

interface Props {
  modelValue?: boolean
  isClosable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  isClosable: false,
})

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'update:modelValue', value: boolean): void
}>()

const isOpen = useVModel(props, 'modelValue', emit, { passive: true })

onUpdated(() => {
  document.documentElement.style.setProperty('--body-overflow', (isOpen.value ? 'hidden' : 'initial'))
})
</script>

<!-- TODO:(danielklein-arch) fix sheet location -->
<template>
  <Sheet v-model:visible="isOpen" only-header-swipe>
    <div p-24px pb-48px>
      <slot />
    </div>
  </Sheet>
</template>

<style>
:root {
  --bottom-sheet-max-height: 90%;
  --bottom-sheet-max-width: 100%;
}
.bottom-sheet-backdrop{
  z-index: 100;
}

.light .bottom-sheet{
    background-color: #ffffff;/* bg-background-primary */
    color: 111827; /* text-neutral-900 */
}
.dark .bottom-sheet{
    background-color: #1F2937;/* bg-neutral-100 */
    color: #F9FAFB; /* text-neutral-900 */
}
.bottom-sheet-header-bar{
    background-color: #6B7280;/* text-neutral-500 */
}
</style>
